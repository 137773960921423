.minimized {
        height: 50px;
        width: 50px;
        --padding-end: 0;
        --padding-start: 0;
         transition: all .3s ease-in-out;
         /* span {
            display: none,
            font-weight: bold;
        }
        ion-icon {
            font-size: 30px;
        } */
}

ion-button.minimized span{
    display: none;
}

ion-button.minimized ion-icon{
    font-size: 30px;
}

.extended {
    width: 170px;
    height: 50px;
    transition: all .3s ease-in-out;
    /* span {
        margin-right: 8px;
        display: block;
    } */
    /* ion-icon {
        margin-right: 8px;
    } */
}

/* ion-button.extended span{
    margin-right: 8px;
    display: block;
} */

ion-button.extended ion-icon{
    margin-right: 10px;
}