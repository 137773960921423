#me-avatar  {
  animation: infiniteSpinning 2s ease-in .5s 1 reverse both running;
}

@keyframes infiniteSpinning {
  from {
    transform: rotateY(360deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.instagram {
  color: #eb445a;
}

.twitter {
  color: #3dc2ff;
}

.linkedin {
  color: #3880ff;
}